<template>
	<div class="home">
		<bir-form></bir-form>
	</div>
</template>

<script>
import birForm from '@/components/pages/report/birform/birForm.vue';

export default {
	components: {
		'bir-form': birForm,
	}
};
</script>

<style>

</style>
