<template>
	<v-card>
		<v-toolbar flat dark color="primary">
			<v-toolbar-title>
				<h3>BIR Form 2307</h3>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text v-if="transaction_data.length > 0">
			<h4>Data Generated. Generate BIR Form?</h4>
			<div style="display: none;">
				<div id="element-to-print" class="bir-form-container" style="display: block;">
					<img src="@/assets/form2307/page1.jpg" style="width: calc(100% - 5px); height: 1270px;"/>
					<!-- Date Range -->
					<p class="form-date-from">{{print_period_from}}</p>
					<p class="form-date-to">{{print_period_to}}</p>
					<!-- Payee's TIN Number -->
					<p class="form-payee-tin-number">{{payee_tin}}</p>
					<!-- Payee's Information -->
					<p class="form-payee-name">{{payee_name}}</p>
					<p class="form-payee-reg-address">{{payee_address}}</p>
					<p class="form-payee-reg-zipcode">{{zip_code}}]]</p>
					<p class="form-payee-foreign-address"></p>
					<!-- Payor's TIN Number -->

					<p class="form-payor-tin-number">201 200 777 00000</p>
					<!-- Payor's Information -->
					<p class="form-payor-name">PETNET Inc.</p>
					<p class="form-payor-reg-address">114 Aguirre St. Legaspi Village, Makati City</p>
					<p class="form-payor-reg-zipcode">1229</p>
					<!-- Income Payments -->
					<table class="form-income-payments">
						<tbody>
							<tr>
								<td>
									Income payment made by top<br>
									witholding agents to their<br>
									local/resident supplier of services<br>
									other than those covered<br>
									by other rates of witholding tax.
								</td>
								<td>{{transaction_data[0].atc_code}}</td>
								<td>{{first_month}}</td>
								<td>{{second_month}}</td>
								<td>{{third_month}}</td>
								<td>{{amount}}</td>
								<td>{{ewt}}</td>
							</tr>
							<!-- <tr>
								<td></td>
								<td>WC050</td>
								<td>10000.00</td>
								<td>10000.00</td>
								<td>10000.00</td>
								<td>30000.00</td>
								<td>10009000.00</td>
							</tr> -->
						</tbody>
					</table>
					<!-- <table class="form-income-total">
						<tbody>
							<tr>
								<td></td>
								<td>WC050</td>
								<td>10000.00</td>
								<td>10000.00</td>
								<td>10000.00</td>
								<td>30000.00</td>
								<td>10009000.00</td>
							</tr>
						</tbody>
					</table> -->
					<!-- Money Payments -->
					<!-- <table class="form-money-payments">
						<tbody>
							<tr>
								<td>Transaction Description Here</td>
								<td>WC050</td>
								<td>1000.00</td>
								<td>1000.00</td>
								<td>1000.00</td>
								<td>3000.00</td>
								<td>1000.00</td>
							</tr>
						</tbody>
					</table> -->
					<table class="form-money-total">
						<tbody>
							<tr>
								<td></td>
								<td>{{transaction_data[0].atc_code}}</td>
								<td></td>
								<td></td>
								<td></td>
								<td>{{amount}}</td>
								<td>{{ewt}}</td>
							</tr>
						</tbody>
					</table>
					<!-- Payee's Signatory -->
					<img class="form-payee-signature" :src="getSignatureFile(signature_url)" />
					<p class="form-payee-signature-name">JENNY LYN G. SABLON </p>
					<p class="form-payee-tax-accreditation-number"></p>
					<p class="form-payee-date-issue"></p>
					<p class="form-payee-date-expiry"></p>
					<!-- Payor's Signatory -->
					<p class="form-payor-signature-name"></p>
					<p class="form-payor-tax-accreditation-number"></p>
					<p class="form-payor-date-issue"></p>
					<p class="form-payor-date-expiry"></p>
					<div class="html2pdf__page-break"></div>
					<img src="@/assets/form2307/page2.jpg" style="width: calc(100% - 5px); height: 1265px; position: relative; top: 65px;"/>
				</div>
			</div>
			<v-btn class="generate-form-button" color="primary" dark @click="generateForm">
				<v-icon 
					icon
					dark
					left
				>
					mdi-file-download-outline
				</v-icon>
				Generate BIR Form 2307
			</v-btn>
			<v-btn class="reset-form-button" color="red" dark @click="resetForm">
				<v-icon 
					icon
					dark
					left
				>
					mdi-refresh-circle
				</v-icon>
				Return to Previous Page
			</v-btn>
		</v-card-text>
		<v-card-text v-if="isFormValid === false">
			<h4>The entry has no taxable entries</h4>
			<v-btn class="reset-form-button not-valid" color="red" dark @click="resetForm">
				<v-icon 
					icon
					dark
					left
				>
					mdi-refresh-circle
				</v-icon>
				Return to Previous Page
			</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
import html2pdf from 'html2pdf.js';
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { parseISO, getMonth, getDate, getYear} from 'date-fns';


export default {
	name: 'birForm',
	data() {
		return {
			isGenerated: true,
			isFormValid: true,
			transaction_data: [],
			signature_url: process.env.VUE_APP_SIGNATORY_URL,
			transaction_type: '',
			batch_number: '',
			date_from: '',
			date_to: '',
			print_period_from: '',
			print_period_to: '',
			min_date: '',
			max_date: '',
			payee_name: '',
			payee_address: '',
			zip_code: '',
			first_month: '',
			second_month: '',
			third_month: '',
			amount: '',
			ewt: '',
			numeral: '',
			payee_tin: ''
		};
	},
	computed: {
		...mapGetters({
			birFormDetails: 'trxdisb/birFormDetails'
		}),
	},
	watch: {
		transaction_data: {
			handler() {},
			deep: true
		}
	},
	async created() {
		const items = this.$route.params.data;

		if (items) {
			this.transaction_type = items.document_type;
			this.batch_number = items.batch_number;
		}

		// let getSavedData = JSON.parse(localStorage.getItem('birFormSavedDetails'));

		// if (getSavedData) {
		// 	getSavedData.forEach(item => {
		// 		this.transaction_type = item.document_type;
		// 		this.batch_number = item.batch_number;
		// 	});


		// 	if (getSavedData.length > 0) {
		// 		this.transaction_data = getSavedData;
		// 		this.generateData();
		// 	} else {
		// 		this.isFormValid = false;
		// 	}
		// } else {
		if (items === undefined) {
			this.isFormValid = false;
		} else {
			await this.$store.dispatch('trxdisb/getBIRFormData', this.batch_number);
			this.transaction_data = this.birFormDetails;

			this.transaction_data.forEach(data => {
				data.document_type = this.transaction_type;
			});
				
			if (this.birFormDetails.length > 0) {
				// localStorage.setItem('birFormSavedDetails', JSON.stringify(this.transaction_data));
				this.generateData();
			} else {
				this.isFormValid = false;
			}
			// }

			
		}
	},
	methods: {
		resetForm() {
			// this.isGenerated = false;
			// localStorage.removeItem('birFormSavedDetails');
			switch(this.transaction_type) {
			case 'JE':
				this.$router.push('/trx-gen-journal');
				break;
			case 'CR':
				this.$router.push('/trxcr');
				break;
			default:
				this.$router.push('/trxdisb');
				break;
			}
		},
		generateData() {
			let getFromMonth = getMonth(parseISO(this.transaction_data[0].period_from)) + 1;
			let getToMonth = getMonth(parseISO(this.transaction_data[0].period_to)) + 1;
			let getFromDate = getDate(parseISO(this.transaction_data[0].period_from));
			let getToDate = getDate(parseISO(this.transaction_data[0].period_to));
			let getFromYear = getYear(parseISO(this.transaction_data[0].period_from));
			let getToYear = getYear(parseISO(this.transaction_data[0].period_to));

			getFromMonth < 10 ? getFromMonth = `0${getFromMonth}` : getFromMonth;
			getFromDate < 10 ? getFromDate = `0${getFromDate}` : getFromDate;
			getToMonth < 10 ? getToMonth = `0${getToMonth}` : getToMonth;
			getToDate < 10 ? getToDate = `0${getToDate}` : getToDate;

			this.print_period_from = `${getFromMonth}${getFromDate}${getFromYear}`;
			this.print_period_to = `${getToMonth}${getToDate}${getToYear}`;
			this.payee_name = this.transaction_data[0].payee_desc;
			this.payee_address = this.transaction_data[0].address;
			this.zip_code = this.transaction_data[0].zip_code;
			this.first_month = numeral(this.transaction_data[0].first_month).format('0, 0.00');
			this.second_month = numeral(this.transaction_data[0].second_month).format('0, 0.00');
			this.third_month = numeral(this.transaction_data[0].third_month).format('0, 0.00');
			this.amount = numeral(this.transaction_data[0].amount).format('0, 0.00');
			this.ewt = numeral(this.transaction_data[0].ewt).format('0, 0.00');
			this.payee_tin = this.transaction_data[0].tin.replace('-', ' ');
			this.isGenerated = true;
		},
		generateForm() {
			var element = document.getElementById('element-to-print');
			var opt = {
				margin: [0, -3, 0, 3],
				image: { type: 'jpeg', quality: 100 },
				html2canvas: { dpi: 100, scale: 1, scrollX: 0, scrollY: 0, backgroundColor: '#FFF' },
				jsPDF: { unit: 'pt', format: 'legal', orientation: 'p' },
				pagebreak: { before: '.page-break', avoid: 'div' }
			};
			
			// New Promise-based usage:
			html2pdf().from(element).set(opt).save();
		},
		getSignatureFile(file) {
			return require('@/assets/'+ file + '.png');
		}
	}
};
</script>
<style scoped>
	.reset-form-button {
		margin-left: 10px;
	}

	.reset-form-button.not-valid {
		margin-left: 0;
	}

	.bir-form-container {
		position: relative;
		font-family: 'Arial' !important;
		font-size: 12px;
	}

	.form-date-from {
		position: absolute;
		top: 148px;
		left: 210px;
		letter-spacing: 10.5px;
	}

	.form-date-to {
		position: absolute;
		top: 148px;
		left: 537px;
		letter-spacing: 10.5px;
	}

	.form-payee-tin-number {
		position: absolute;
		top: 191px;
		left: 281px;
		letter-spacing: 11.5px;
	}

	.form-payee-name {
		position: absolute;
		top: 227px;
		left: 48px;
	}

	.form-payee-reg-address {
		position: absolute;
		top: 265px;
		left: 48px;
	}

	.form-payee-reg-zipcode {
		position: absolute;
		top: 265px;
		left: 726px;
		letter-spacing: 8.5px;
	}

	.form-payee-foreign-address {
		position: absolute;
		top: 305px;
		left: 48px;
	}

	.form-payor-tin-number {
		position: absolute;
		top: 349px;
		left: 281px;
		letter-spacing: 11.5px;
	}

	.form-payor-name {
		position: absolute;
		top: 384px;
		left: 48px;
	}

	.form-payor-reg-address {
		position: absolute;
		top: 422px;
		left: 48px;
	}

	.form-payor-reg-zipcode {
		position: absolute;
		top: 422px;
		left: 726px;
		letter-spacing: 8.5px;
	}

	.form-income-payments {
		position: absolute;
		top: 495px;
		left: 26px;
		font-size: 13px;
	}

	.form-income-total {
		position: absolute;
		top: 682px;
		left: 26px;
		font-size: 13px;
	}

	.form-money-payments {
		position: absolute;
		top: 725px;
		left: 26px;
		font-size: 13px;
	}

	.form-money-total {
		position: absolute;
		top: 912px;
		left: 26px;
		font-size: 13px;
	}

	.form-income-payments tr:first-child td,
	.form-money-payments tr:first-child td {
		height: 18px;
	}

	.form-income-payments tr:nth-of-type(4) td,
	.form-money-payments tr:nth-of-type(4) td {
		height: 20px;
	}

	.form-income-payments tr:nth-of-type(7) td,
	.form-money-payments tr:nth-of-type(7) td {
		height: 20px;
	}

	.form-income-payments tr td:first-child,
	.form-money-payments tr td:first-child {
		width: 207px;
		line-height: 1.5;
	}

	.form-income-total tr td:first-child,
	.form-money-total tr td:first-child {
		width: 207px;
	}

	.form-income-payments tr td:nth-of-type(2),
	.form-money-payments tr td:nth-of-type(2) {
		width: 50px;
		text-align: left;
		vertical-align: top;
	}

	.form-income-total tr td:nth-of-type(2),
	.form-money-total tr td:nth-of-type(2) {
		width: 50px;
		text-align: left;
	}

	.form-income-payments tr td:nth-of-type(3),
	.form-income-payments tr td:nth-of-type(4),
	.form-income-payments tr td:nth-of-type(5),
	.form-income-payments tr td:nth-of-type(6),
	.form-money-payments tr td:nth-of-type(3),
	.form-money-payments tr td:nth-of-type(4),
	.form-money-payments tr td:nth-of-type(5),
	.form-money-payments tr td:nth-of-type(6) {
		width: 95px;
		text-align: right;
		vertical-align: top;
	}

	.form-income-total tr td:nth-of-type(3),
	.form-money-total tr td:nth-of-type(3) {
		width: 102px;
		text-align: right;
	}


	.form-income-total tr td:nth-of-type(4),
	.form-income-total tr td:nth-of-type(5),
	.form-money-total tr td:nth-of-type(4),
	.form-money-total tr td:nth-of-type(5) {
		width: 95px;
		text-align: right;
	}

	.form-income-total tr td:nth-of-type(6),
	.form-money-total tr td:nth-of-type(6) {
		width: 88px;
		text-align: right;
	}

	.form-income-payments tr td:nth-of-type(7),
	.form-money-payments tr td:nth-of-type(7) {
		text-align: right;
		width: 108px;
		vertical-align: top;
	}

	.form-income-total tr td:nth-of-type(7),
	.form-money-total tr td:nth-of-type(7) {
		text-align: right;
		width: 106px;
	}

	.form-income-payments tr:nth-of-type(10) td,
	.form-money-payments tr:nth-of-type(10) td {
		height: 17px;
	}

	.form-income-payments tr:last-child td,
	.form-money-payments tr:last-child td {
		height: 15px;
	}

	.form-payee-signature-name {
		position: absolute;
		top: 1000px;
		left: 300px;
		width: 200px;
		text-align: center;
	}

	.form-payee-tax-accreditation-number {
		position: absolute;
		top: 1055px;
		left: 183px;
	}

	.form-payee-date-issue {
		position: absolute;
		top: 1055px;
		left: 425px;
		letter-spacing: 10.5px;
	}
	
	.form-payee-date-expiry {
		position: absolute;
		top: 1055px;
		left: 652px;
		letter-spacing: 10.5px;
	}

	.form-payee-signature {
		position: absolute;
		top: 978px;
		left: 363px;
		height: 25px;
		width: 65px;
		text-align: center;
	}

	.form-payor-signature-name {
		position: absolute;
		top: 1105px;
		left: 300px;
		width: 200px;
		text-align: center;
	}

	.form-payor-tax-accreditation-number {
		position: absolute;
		top: 1160px;
		left: 183px;
	}

	.form-payor-date-issue {
		position: absolute;
		top: 1160px;
		left: 425px;
		letter-spacing: 10.5px;
	}
	
	.form-payor-date-expiry {
		position: absolute;
		top: 1160px;
		left: 652px;
		letter-spacing: 10.5px;
	}
</style>